import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import {Constants} from "../../models/constants";
import {Router} from "@angular/router";

@Component({
  selector: 'oei-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit{
  text: string;
  logoIcon: string;
  homeIcon: string;
  loggedIn: boolean;
  backIcon;
  menuIcon;

  constructor(private route:Router, private menu: MenuController) {
    this.text = 'Header Component';
    this.logoIcon = Constants.OEI_LOGO;
    this.homeIcon = Constants.OEI_HOME;
    this.backIcon = Constants.OEI_BACK;
    this.menuIcon = Constants.OEI_MENU_SIDE;
    this.loggedIn = false;


  }

  ngOnInit() {
    // console.log(this.route.url);
    if (this.route.url === ('/dashboard')){

      this.homeIcon = Constants.OEI_HOME;
      
    } 
    else if (this.route.url === ('/licensing')){
      this.homeIcon = Constants.OEI_BACK;

    }
  }

  async openFirst() {
    this.menu.enable(true, 'first');
    // console.log("got here");
    // await this.menu.open();
  }

  setLoggedIn(status: boolean){
    this.loggedIn = status;
    // console.log(status);

  }

  logout(){
    if (this.homeIcon === Constants.OEI_HOME && this.route.url === ('/dashboard')){
      this.route.navigate(['/login']);
    }
      else if (this.homeIcon === Constants.OEI_BACK){
        this.route.navigateByUrl('simrecords');
      }
    else{
      this.route.navigateByUrl('dashboard');
    }
    // 
  }


}
