import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';
import * as tools from '../models/tools';

@Injectable({
  providedIn: 'root'
})
export class WebNotifierService {

  constructor() {

  }

  notify(observ: Observable<any>, context): Observable<any> {
    return observ.pipe(map((data) => {
      let cmd = data;

      return [context, cmd];
    }));
  }
}
