import { Component, OnInit, Input } from '@angular/core';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import { Router } from '@angular/router';
import {ContextService} from "../../service/context.service";
import { Licenses, Simulator } from 'src/app/models/simulator';
import {SimulatorService} from "../../service/simulator.service";


@Component({
  selector: 'app-sim-details',
  templateUrl: './sim-details.component.html',
  styleUrls: ['./sim-details.component.scss'],
})
export class SimDetailsComponent implements OnInit {
  title: string;
  message: string;
  @Input() simulator: Simulator;
  licenses: Licenses [];

  constructor(public alertController: AlertController, private context: ContextService, private router: Router, private popover: PopoverController, private simulatorServ: SimulatorService) {
    this.licenses = [];
   }

  ngOnInit() {
    this.message = "Would you like to make an edit?";
    this.title = "Make an Edit";
    // this.simulator = new Simulator('','','','','');
    // console.log(this.simulator);
    console.log(this.simulator);
    this.context.editsimulators.push(this.simulator);
    this.licenses = this.simulator.licenses;
    // console.log(this.simulator.licenses);
  }

  saveEdit(){
    this.message = "Would you like to save your edit?";
    this.title = "Save Edit";
  }

  deleteSim(){
    this.message = "Are you sure you would like to delete this simulator? (Cannot be undone)";
    this.title = "Delete Simulator";
    this.deleteAlert();
  }
  async presentAlert() {
    const alert = await this.alertController.create({
            cssClass: 'alert-class',
            header: this.title,
            message: this.message,
            mode: 'ios',
            buttons: [{
              text: 'YES',
              handler: () => { //takes the data
                alert.dismiss();
                this.context.editSimulator(this.simulator);
                this.context.info = false;
                // console.log(this.context.info);
                this.router.navigate(['/licensing']);
                this.popover.dismiss();

              }
            }, {
              text: 'NO',
              handler: () => {
                alert.dismiss();
              }
            }],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }

  async deleteAlert() {
    let usr = this.context.getLoggedInUser();
    const alert = await this.alertController.create({
            cssClass: 'alert-class',
            header: this.title,
            message: this.message,
            mode: 'ios',
            buttons: [{
              text: 'YES',
              handler: () => { //takes the data
                //console.log(this.simulator.serial);
                this.simulatorServ.deleteSimulator(usr, this.simulator.serial).subscribe(
                  (data)=> {

                  },
                  (err)=>{},
                  ()=>{
                    this.popover.dismiss({});
                    alert.dismiss();


                  }

                );

                // this.context.editSimulator(this.simulator);
                // this.context.info = false;
                // console.log(this.context.info);

              }
            }, {
              text: 'NO',
              handler: () => {
                alert.dismiss();
              }
            }],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }

}
