export class Constants{
  static APISERVER= 'www.oeisim.com';
  //static APISERVER= '127.0.0.1';
  static APISERVER_PORT= '8080';

  static OEI_LOGO = 'assets/img/OEILogoHeaderWhite.png';
  static OEI_HOME = 'assets/img/Icon-Home-White.png';
  static OEI_LOGO_HEADER_BLACK = 'assets/img/OEILogoHeader.png';
  static OEI_LOGO_BLACK = 'assets/img/OEILogo.png';
  static OEI_BACKGROUND = '/assets/img/RedBackground.png';
  static OEI_BACK = 'assets/img/back-btn.png';
  static OEI_MENU_SIDE = 'assets/img/menu-btn.png';
}
