import {HttpHeaders} from '@angular/common/http';
import * as tools from './tools';
import {Constants} from "./constants";

export class WebService {
  baseURL: string;
  webserviceURL: string;
  header;
  authorized: boolean;

  constructor(hostname) {
    this.baseURL = 'http://' + hostname ;
    this.webserviceURL = this.baseURL + ':'+Constants.APISERVER_PORT;
    this.setDefaultHeaders();
    this.authorized = false;
  }
  setAuthorization(token) {
    if (!tools.isNull(token)) {
      this.authorized = true;
      const authStr = token;
      this.header = this.header.set('X-Authorization', authStr); // Must set this.header= this.header.set
    }
  }
  isAuthorized() {
    return this.authorized;
  }
  setDefaultHeaders() {
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8',
      'Accept-Language': 'en-US,en;q=0.5'});
  }
  getHeader() {
    return this.header;
  }
}
