import {OEIError} from "./oeierror";
import * as tools from "../models/tools";
import {Simulator} from "../models/simulator";
export class WebResponse {
  command: any;
  error: OEIError;
  status: boolean;

  constructor() {

  }
}


export class Resp_Simulator_getAll extends WebResponse{
  simList;
  constructor(rpy) {
    super();
    this.simList = [];
    if(!tools.isNull(rpy.RpyName)){
        // Add rpyName in here
    }
    if(!tools.isNull(rpy.Simulators)){
      for(let sim of rpy.Simulators){
        console.log("Process web service");
        let tempSim = new Simulator(sim.productType, sim.gender, sim.ethnicity, sim.productionDate, sim.serialNumber, '',sim.productType,sim.woundConfiguration);
        tempSim.licenses = sim.licenses;
        this.simList.push(tempSim);
      }
    }

  }

}
