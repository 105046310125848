import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'simrecords',
    loadChildren: () => import('./pages/simrecords/simrecords.module').then( m => m.SimrecordsPageModule)
  },
  {
    path: 'licensing',
    loadChildren: () => import('./pages/licensing/licensing.module').then( m => m.LicensingPageModule)
  },
  {
    path: 'instances',
    loadChildren: () => import('./pages/instances/instances.module').then( m => m.InstancesPageModule)
  },
  {
    path: 'scenario-manager',
    loadChildren: () => import('./pages/scenario-manager/scenario-manager.module').then( m => m.ScenarioManagerPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
