import {WebService} from "./webService";
import {Constants} from "./constants";

export class User {

  private _fname: string;
  private _lname: string;
  private _username: string;
  private _role;


  web: WebService;

  constructor() {
    this._fname = '';
    this._lname = '';
    this._username = '';
    this._role = '';
    this._fname = '';
    this.web = new WebService(Constants.APISERVER);
  }

  getFirstName(): string {
    return this._fname;
  }

  setFirstName(value: string) {
    this._fname = value;
  }

  getLastName(): string {
    return this._lname;
  }

  setLastName(value: string) {
    this._lname = value;
  }

  getUsername(): string {
    return this._username;
  }

  setUsername(value: string) {
    this._username = value;
  }

  getRole() {
    return this._role;
  }

  setRole(value) {
    this._role = value;
  }

}
