export class ScenarioPackage {
    name:string;
    packageID: number;

    constructor() {}
}
export class scenarioUpgrade {
    name:string;
    packageID: number;
    packageURL: string;

    constructor() {}
}
export enum LicenseType {
    MONITOR,
    SCENARIO_PACKAGE,
    COMBO,

    UNKNOWN
}
export class Licenses{
    packageKey: string;
    iSimulate;  // TO BE REMOVED
    packageNum; // TO BE REMOVED
    connectorType;
    scenarioPackage: Array<ScenarioPackage>;
    scenarioUpgrade: scenarioUpgrade;
    updated;
    scenarioPack;
    licenseNum;

    monitor;
    key;
    _id;
    item1;
    item2;
    date;
    condition;

    constructor(){
        this.packageNum ='';
        this.iSimulate = '';
        this.updated = '';
        this.scenarioPack = '';
        this.licenseNum = '';
        this.key = '';
        this._id = '';
        this.item1 = '';
        this.item2 = '';
        this.date = '';
        this.condition = '';


    }

    setPackageNum(packageNum){
        this.packageNum = packageNum;
    }




}
export class Product {
    productName;
    productId;
    uniqueIdentifier;
    productClass;
    isRetired;
    _id;

     constructor(){
        this.productName = '';
        this.productId = '';
        this.uniqueIdentifier = '';
        this.productClass = '';
        this.isRetired = '';
        this._id = '';

     }
}

export class Simulator{
    type;
    name;
    gender;
    ethnicity;
    productDate;
    woundPattern;
    serial;
    productType: Product;
    license: Licenses[];
    licenses;
    _id;
    _v;
    notes;


    constructor(type,gender,ethnicity,productDate,serial,name,productType, woundConfiguration){
        this.type = type;
        this.name = name;
        this.gender = gender;
        this.ethnicity = ethnicity;
        this.productDate = productDate;
        this.serial = serial;
        this.license=[];
        this.licenses = [];
        this.productType = new Product();
        this.productType = productType;
        this.woundPattern = woundConfiguration;
        this.notes = '';
        this._v = '';
        this._id = '';
    }
}

export enum Gender {
    MALE,
    FEMALE,

    UNKNOWN = 99
}
export enum Ethnicity {
    CAUCASIAN,
    HISPANIC,
    ASIAN,
    AFRICAN

}
export class SimulatorFinal {
    type: number;
    gender: Gender;
    ethnicity: Ethnicity;
    productDate: Date;
    serial: string;
    license: Licenses[];





      findLicense(license){
        this.license.find(obj => {
            return obj.licenseNum = license;
          })
    }
}
