import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { AlertController, PopoverController } from '@ionic/angular';
import { AddLicenseComponent } from '../add-license/add-license.component';
import { Licenses, Simulator } from '../../models/simulator';
import { ContextService } from "../../service/context.service";
import { Router } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FormControl, Validators } from '@angular/forms';
import { ProductAll } from '../../models/productInformation';
import { SimulatorService } from "../../service/simulator.service";
import { Resp_Simulator_getAll } from "../../models/webResponse";


@Component({
  selector: 'app-license-gen',
  templateUrl: './license-gen.component.html',
  styleUrls: ['./license-gen.component.scss'],
})
export class LicenseGenComponent implements OnInit{
  licenseForm: FormGroup;
  @ViewChild(HeaderComponent) header: HeaderComponent;
  title: string;
  message: string;
  selectedOpt: string;
  woundPattern: string;
  gender: string;
  sim: Simulator;
  loggedIn: boolean;
  licenses: Licenses[];
  editSim: Simulator;
  valid: boolean;
  control: FormControl;
  simulator: NgModel;
  info: boolean;
  products: ProductAll;
  hideWound: boolean;
  updatePackage: boolean;
  selectLicense: Licenses;
  controlDate: FormControl;
  selected: boolean;
  date;
  constructor(public alertController: AlertController, public popoverController: PopoverController, private simulatorServ: SimulatorService, private context: ContextService, private router: Router, private formBuilder: FormBuilder) {
    this.licenses = [];
    this.info = false;
    this.hideWound = false;
    this.updatePackage = false;
    this.selectLicense = new Licenses();
    this.products = new ProductAll();
    this.buildForm();
    this.setEditSim();
    this.date = '';

  }

  ngOnInit() {
    this.licenses = [];
    this.message = 'Are you sure you would like to generate this simulator?';
    this.title = 'Confirm';
    this.selectedOpt = '';

    if (this.editSim !== undefined) {
      this.licenses = this.context.editSim.license;
    }


  }


  buildForm() {

    this.licenseForm = this.formBuilder.group({
      prodDate: [''],
      serialNum: [''],
      simulator: [''],
      ethnicity: [''],
      gender: [''],
      allergies: [''],
      mechOther: [''],
      Artillery: [''],
      RArmInput: [''],
      RLegInput: [''],
      LArmInput: [''],
      LLegInput: [''],

    });
  }
/*  ngAfterViewInit() {
    // this.licenses = [];
    console.log('About to add Simulator 1');
    this.buildForm();
    this.context.info === false;
    if (this.context.editsimulators !== undefined && this.context.info === false) {
      // this.licenses = this.context.editsimulators[0].license;
      // this.licenses = this.context.editsimulators[0].license;
      // this.gender = this.context.editsimulators[0].gender;
      // this.selectedOpt = this.context.editsimulators[0].woundPattern;
      // this.setEditSim();


    }
    if (this.context.editsimulators[0] !== undefined) {
      this.setSim();
      this.setEditSim();
    }
  }*/

  onEnter(){
    console.log('About to add Simulator 3');
    this.buildForm();
    this.context.info === false;
    if (this.context.editsimulators !== undefined && this.context.info === false) {
      // this.licenses = this.context.editsimulators[0].license;
      // this.licenses = this.context.editsimulators[0].license;
      // this.gender = this.context.editsimulators[0].gender;
      // this.selectedOpt = this.context.editsimulators[0].woundPattern;
      // this.setEditSim();


    }
    if (this.context.editsimulators[0] !== undefined) {
      this.setSim();
      this.setEditSim();
    }
  }

  setEditSim() {
    if (this.context.editsimulators[0] !== undefined) {
      this.licenseForm.patchValue({
        serialNum: this.context.editsimulators[0].serial,
        simulator: this.context.editsimulators[0].productType.productId.toString(),
        ethnicity: this.context.editsimulators[0].ethnicity,
        prodDate: this.context.editsimulators[0].productDate
      });
      this.date = this.context.editsimulators[0].productDate;

    }



  }

  editing() {
    // this.context.info = true;

  }

  setSim() {
    this.licenses = this.context.editsimulators[0].licenses;
    this.gender = this.context.editsimulators[0].gender;
    this.context.info = false;
    this.selectedOpt = this.context.editsimulators[0].woundPattern;
    this.date = this.context.editsimulators[0].productDate;

  }

  genderSelect(gender) {
    this.gender = gender;

  }

  productSelect(ev) {
    if (Number(ev.target.value) === 3010 || Number(ev.target.value) === 3013) {
      this.hideWound = true;
    }
    else {
      this.hideWound = false;
    }
    if (Number(ev.target.value) === 3003 || Number(ev.target.value) === 3004 || Number(ev.target.value) === 3008 || Number(ev.target.value) === 3009) {
      this.updatePackage = true;
    } else {
      this.updatePackage = false;
    }
    // console.log(ev.target.value);
    // if()
  }

  validateInput(key) {
    this.control = new FormControl(key, Validators.pattern('([A-Za-z0-9]{6}\-){3}([A-Za-z0-9]{6})'));
    if (this.control.status === 'VALID' && key !== '') {
      this.valid = true;
      this.licenseForm.value.prodDate = key;
    }
    else {
      this.valid = false;
    }
    // console.log(this.valid, key);

  }

  simAdd() {
    let usr = this.context.getLoggedInUser();
    // Adding new Simulator
    if (this.licenseForm.value.serialNum === '' || this.gender === '' || this.licenseForm.value.prodDate === '' || this.licenseForm.value.ethnicity === '' || this.licenseForm.value.simulator === '') {
      this.message = "Please check that all the information (gender, serial number, ethnicity, production date, product type) has been entered and selected."
      this.title = "Missing Information"
      this.FillInAlert();
      console.log(this.licenseForm.value.serialNum, this.licenseForm.value.gender, this.licenseForm.value.prodDate, this.licenseForm.value.ethnicity, this.licenseForm.value.simulator);
    } else {

      if (this.context.editsimulators[0] === undefined) {
        this.sim = new Simulator(this.licenseForm.value.simulator, this.gender, this.licenseForm.value.ethnicity, this.licenseForm.value.prodDate, this.licenseForm.value.serialNum, 'name', '', this.selectedOpt);
        if (this.hideWound === true) {
          this.sim.woundPattern = '';

        }
        console.log('About to add Simulator 2');
        this.simulatorServ.addSimulator(usr, this.sim).subscribe(
          (data) => {
            // console.log(data);
            this.getSimulators();

            this.router.navigateByUrl('simrecords');
            this.context.editsimulators = [];
          },
          (err) => {
            this.title = "Problem with Adding Simulator";
            this.message = "Please check that the new Simulator's information is correct and try to generate again."
            this.SimulatorErrorAlert()
            // console.log("Error");

          },
          () => { }
        );


      }
      // Editing Simulator
      else {
        // Editing the serial or product type (delete old Simulator, make new one)
        if (this.context.editsimulators[0].serial !== this.licenseForm.value.serialNum || this.context.editsimulators[0].productType.productId.toString() !== this.licenseForm.value.simulator) {
          this.simulatorServ.deleteSimulator(usr, this.context.editsimulators[0].serial).subscribe(
            (data) => {
              // console.log(data);
            },
            (err) => {

            },
            () => { }
          );

          const tempsim = new Simulator(this.licenseForm.value.simulator, this.gender, this.licenseForm.value.ethnicity, this.licenseForm.value.prodDate, this.licenseForm.value.serialNum, 'name', '', String(this.selectedOpt));
          if (this.hideWound !== true) {
            tempsim.woundPattern = '';

          }
          this.simulatorServ.addSimulator(usr, tempsim).subscribe(
            (data) => {
              // console.log(data);
            },
            (err) => {
              this.title = "Problem with Adding Simulator";
              this.message = "Please check that the information entered is correct."
              this.SimulatorErrorAlert();
              // console.log("Error");

            },
            () => { }
          );

          this.getSimulators();

          // this.router.navigateByUrl('simrecords');
          this.context.editsimulators = [];

          // Edit Simulator gender, ethnicity, date, notes, wound configuration
        } else {
          this.context.editsimulators[0].gender = this.gender;
          this.context.editsimulators[0].productDate = this.licenseForm.value.prodDate;
          this.context.editsimulators[0].serial = this.licenseForm.value.serialNum;
          this.context.editsimulators[0].type = this.licenseForm.value.simulator;
          this.context.editsimulators[0].ethnicity = this.licenseForm.value.ethnicity;
          this.simulatorServ.updateSimulator(usr, this.context.editsimulators[0]).subscribe(
            (data) => {
              this.getSimulators();
            },
            (err) => {
            },
            () => { }
          )
          this.router.navigateByUrl('simrecords');
          this.context.editsimulators = [];
        }
      }
    }
  }

  getSimulators() {
    let usr = this.context.getLoggedInUser();
    this.simulatorServ.getAllSimulators(usr).subscribe(
      (data) => {
        let usr = data[0];
        let rpy = data[1];
        let rpyGetSimulator = new Resp_Simulator_getAll(rpy);
        // console.log(this.simulators[0].productType);
        // console.log('Got return from getAllSimulators');
        // console.log('Trying to catch this')
      },
      (err) => { },
      () => { }
    );
    // console.log(this.context.getSimulator());

  }



  saveWound(number) {
    this.selectedOpt = number;
  }

  validateDate(key) {
    // this.controlDate = new FormControl(key.target.value, Validators.pattern('((20[0-9]{2})-([0][1-9]|[1][0-2])-([0][1-9]|[1-2][0-9]|[3][0-1])T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z)'));
    // if (this.controlDate.status === 'VALID' && key.target.value !== '') {
      // this.valid = true;
      // console.log(key.target.value);
      const event = new Date(key.target.value.slice(0,10));
      // console.log(event.toISOString());
     this.licenseForm.value.prodDate = event.toISOString();
     this.date = event.toISOString();
    // }
    // else {
    //   this.valid = false;
    //   this.licenseForm.value.prodDate = '';
    // }

  }

  removeLicense() {

    let usr = this.context.getLoggedInUser();
    let temp = new Licenses();
    const index = this.licenses.indexOf(this.selectLicense);
    this.simulatorServ.removeSimulatorLicense(usr, this.licenseForm.value.serialNum, this.selectLicense.iSimulate, '999', '000', this.selectLicense.key, this.selectLicense._id, this.selectLicense.date, this.selectLicense.item1, this.selectLicense.item2).subscribe(
      (data) => {
      },
      (err) => { },
      () => { }
    );
    if (index > -1) {
      this.licenses.splice(index, 1); // 2nd parameter means remove one item only
    }
    this.getSimulators();

  }

  selectedLicense(license) {
    this.selectLicense = license;
  }
  upgradePackage() {
    let usr = this.context.getLoggedInUser();
    this.simulatorServ.generateSimulatorUpgrade(usr, this.licenseForm.value.serialNum).subscribe(
      (data) => { },
      (err) => { },
      () => { }
    );
  }

  showMoreDetails() {

  }

  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'alert-class',
      header: this.title,
      message: this.message,
      mode: 'ios',
      buttons: [{
        text: 'YES',
        handler: () => { //takes the data
          this.simAdd();
        }
      }, {
        text: 'NO',
        handler: () => {
          alert.dismiss();
        }
      }],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }

  async RemoveLicenseAlert() {
    const alert = await this.alertController.create({
      cssClass: 'alert-class',
      header: "Remove License",
      message: "Are you sure you would like to delete this license?",
      mode: 'ios',
      buttons: [{
        text: 'YES',
        handler: () => { //takes the data
          // console.log("removed");
          this.removeLicense();
        }
      }, {
        text: 'NO',
        handler: () => {
          alert.dismiss();
        }
      }],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }

  async SimulatorErrorAlert() {
    const alert = await this.alertController.create({
      cssClass: 'alert-class',
      header: this.title,
      message: this.message,
      mode: 'ios',
      buttons: [{
        text: 'Return to editing',
        handler: () => {
          alert.dismiss();
        }
      }],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }

  async FillInAlert() {
    const alert = await this.alertController.create({
      cssClass: 'alert-class',
      header: this.title,
      message: this.message,
      mode: 'ios',
      buttons: [{
        text: 'Return to editing',
        handler: () => {
          alert.dismiss();
        }
      }],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }


  async AddLicensePopover() {
    console.log(this.context.editSim, this.licenseForm.value.serialNum);
    console.log(this.context.simulators);

    const popover = await this.popoverController.create({
      component: AddLicenseComponent,
      cssClass: 'license-add-popover',
      translucent: true,
      showBackdrop: true,
      componentProps: {
        licensesArr: this.licenses,
        serialNum: this.licenseForm.value.serialNum,
      }
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
  }



}

