import { Injectable } from '@angular/core';
import { Licenses, Simulator } from '../models/simulator';
import {User} from "../models/user";

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  user: User;
  simulators: Simulator [];
  editsimulators: Simulator [];
  editSim: Simulator;
  info: boolean;

  constructor() {
    this.user = new User();
    this.tempSimulator();
    this.editsimulators = [];
    console.log(this.simulators);
  }


  addLoggedInUser(user: User){
    this.user = user;
  }
  getLoggedInUser(){
    return this.user;
  }

  tempSimulator(){
    this.simulators = [];

    for(let i = 0; i < 10; i++){
      let temp = new Simulator('3010','Male','AA','01-30-22', i+1, 'Tactical-Pro', '', '');
      temp.woundPattern = 2;
      temp.license = []
      for(let i = 0; i<5; i++){
        let tempLicence = new Licenses();
        tempLicence.licenseNum = 'package number '+i ;
        temp.license.push(tempLicence);
      }
      this.simulators.push(temp);
    }


  }

  getSimulator(){
    return this.simulators;
  }

  setSimulator(simarr){
    this.simulators = simarr;
  }

  addSimulator(sim){
    this.simulators.push(sim);
  }

  editSimulator(sim){
    this.info = false;
    this.editsimulators = [];
    this.editsimulators.push(sim);
    this.editSim = sim;

  }

  addLicense(license, sim: Simulator){
    sim.license = [];
    sim.license.push(license);
  }
}
