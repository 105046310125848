export class Product{
    name;
    type;
    constructor(name, type){
        this.name = name;
        this.type = type;
    }
}

export class ProductAll{
    arr: Product [];

    constructor(){
        this.arr = [];
        this.getProducts();
    }

    getProducts(){
        let temp;
        this.arr.push(new Product('TCCS Pro Basic', 3013));
        this.arr.push(new Product('TCCS Pro Plus', 3010));
        this.arr.push(new Product('TCCS Pro PFC', 3007));
        this.arr.push(new Product('TCCS OEM', 3003));
        this.arr.push(new Product('TCCS PLUS', 3004));
        this.arr.push(new Product('RealMom OEM', 3005));
        this.arr.push(new Product('THCT OEM', 3006));


        //this.arr.push(new Product('TCCS Pro', 3008));
        // this.arr.push(new Product('Tactical-PLUS', 3009));
        //this.arr.push(new Product('RealMom-OEM', 3011));
        //this.arr.push(new Product('RealMom-Pro', 3012));


     }

     getName(type){
        this.arr.find(obj => {
            return obj.type === type
          })
     }
}
