import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private menu: MenuController, private router: Router) {}

  async openFirst() {
    // this.menu.enable(true, 'first');
    console.log(" here");
    await this.menu.open();
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }


  goInstances(){
    this.router.navigateByUrl('instances');


  }

  goRecords(){
    this.router.navigateByUrl('simrecords');

    
  }
}
