import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {WebNotifierService} from "./web-notifier.service";
import * as tools from "../models/tools";
import {User} from "../models/user";
import {throwError} from "rxjs";
import { Simulator } from '../models/simulator';

@Injectable({
  providedIn: 'root'
})
export class SimulatorService extends WebNotifierService{

  constructor(private http: HttpClient) {
    super();
  }

  getAllSimulators(user: User){
    let url = null;
    let header = null;

    if (tools.isNull(User) ) {
      return throwError('Invalid User');
    }

    url = user.web.webserviceURL + '/api/simulator/getAllSimulators';
    header = user.web.getHeader();

    const body = {
      Command: {
        Name:"Cmd_Simulator_GetAll"
      }
    };
    return this.notify(this.http.post(url, body, {headers: header}), user);
  }
  getAllProducts(user: User) {
    let url = null;
    let header = null;

    if (tools.isNull(User) ) {
      return throwError('Invalid User');
    }

    url = user.web.webserviceURL + '/api/simulator/getAllProducts';
    header = user.web.getHeader();

    const body = {
      Command: {
        Name:"Simulator_GetAllProducts"
      }
    };
    return this.notify(this.http.post(url, body, {headers: header}), user);
  }
  getSimulator(user: User, serialNumber: string){
    let url = null;
    let header = null;

    if (tools.isNull(User) ) {
      return throwError('Invalid User');
    }

    url = user.web.webserviceURL + '/api/simulator/getSimulator';
    header = user.web.getHeader();

    const body = {
      Command: {
        Name:"Cmd_Simulator_GetSim",
        SerialNumber: serialNumber
      }
    };
    return this.notify(this.http.post(url, body, {headers: header}), user);
  }
  addSimulator(user: User, sim: Simulator){
    let url = null;
    let header = null;

    if (tools.isNull(User) ) {
      return throwError('Invalid User');
    }

    url = user.web.webserviceURL + '/api/simulator/addSimulator';
    header = user.web.getHeader();

    const body = {
      Command: {
        Name:"Cmd_Simulator_Add",
        Simulator: {
          serialNumber: sim.serial,
          ethnicity: sim.ethnicity,
          gender: sim.gender,
          woundConfiguration: sim.woundPattern,
          productType: sim.type,
          notes: '',
          licenses: sim.licenses,
          productionDate: sim.productDate
        }
      }
    };
    return this.notify(this.http.post(url, body, {headers: header}), user);
  }
  deleteSimulator(user: User, serialNumber){
    let url = null;
    let header = null;

    if (tools.isNull(User) ) {
      return throwError('Invalid User');
    }

    url = user.web.webserviceURL + '/api/simulator/deleteSimulator';
    header = user.web.getHeader();

    const body = {
      Command: {
        Name: "Cmd_Simulator_Delete",
        SerialNumber: serialNumber
      }
    };
    return this.notify(this.http.post(url, body, {headers: header}), user);
  }
  updateSimulator(user: User, sim){
    let url = null;
    let header = null;

    if (tools.isNull(User) ) {
      return throwError('Invalid User');
    }

    url = user.web.webserviceURL + '/api/simulator/updateSimulator';
    header = user.web.getHeader();

    const body = {
      Command: {
          Name:"Cmd_Simulator_UpdateSim",
          Simulator:{
            _id: sim._id,
            productType: {
              _id: sim.productType._id,
              productId: sim.productType.productId,
              productName : sim.productType.productName,
              uniqueIdentifier : sim.productType.uniqueIdentifier,
              productClass : sim.productType.productClass,
              isRetired : sim.productType.isRetired,
              __v: sim.productType.__v
            },
            serialNumber: sim.serial,
            ethnicity: sim.ethnicity,
            productionDate: sim.productDate,
            gender: sim.gender,
            woundConfiguration: sim.woundPattern,
            licenses: sim.licenses,
            notes: sim.notes,
            __v: sim.__v
          }
        }
      
      
    };
    console.log(body);
    return this.notify(this.http.post(url, body, {headers: header}), user);
  }
  generateSimulatorLicense(user: User, serialNumber, monitor, scenario, condition){
    let url = null;
    let header = null;

    if (tools.isNull(User) ) {
      return throwError('Invalid User');
    }

    url = user.web.webserviceURL + '/api/simulator/addLicense';
    header = user.web.getHeader();

    const body = {
      Command: {
        Name: "Cmd_Simulator_AddLicense",
        License:{
          Monitor:monitor,
          ScenarioPackage:scenario,
          Condition:condition
        },
        SerialNumber: serialNumber
      }
    };
    return this.notify(this.http.post(url, body, {headers: header}), user);
  }
  removeSimulatorLicense(user: User, serialNumber, monitor, scenario, condition, key, id, date, item1, item2){
    let url = null;
    let header = null;

    if (tools.isNull(User) ) {
      return throwError('Invalid User');
    }

    url = user.web.webserviceURL + '/api/simulator/deleteLicense';
    header = user.web.getHeader();

    const body = {
      Command: {
        Name: "Cmd_Simulator_DeleteLicense",
        SerialNumber: serialNumber,
        License:{
          Monitor:monitor,
          ScenarioPackage:scenario,
          Condition:condition,
          item1: item1,
          item2: item2,
          licenseStr: serialNumber+"_"+monitor+"_"+scenario+"_"+condition+"_000_000",
          key: key,
          date: date,
          _id: id,
        }
      }
    };
    return this.notify(this.http.post(url, body, {headers: header}), user);
  }
  generateSimulatorUpgrade(user: User, serialNumber: string){
    let url = null;
    let header = null;

    if (tools.isNull(User) ) {
      return throwError('Invalid User');
    }

    url = user.web.webserviceURL + '/api/simulator/generateUpgrade';
    header = user.web.getHeader();

    const body = {
      Command: {
        Name:"Cmd_Simulator_GenerateUpgrade",
        SerialNumber: serialNumber
      }
    };
    return this.notify(this.http.post(url, body, {headers: header}), user);
  }
}
