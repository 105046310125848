import { Component, OnInit, Input} from '@angular/core';
import { AlertController, PopoverController } from '@ionic/angular';
import { Licenses } from 'src/app/models/simulator';
import {ContextService} from "../../service/context.service";
import {SimulatorService} from "../../service/simulator.service";
import { FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-add-license',
  templateUrl: './add-license.component.html',
  styleUrls: ['./add-license.component.scss'],
})
export class AddLicenseComponent implements OnInit {
  message;
  title;
  packageNum;
  iSimulate;
  scenarioPackOpt;
  scenarioPack;
  @Input() licensesArr;
  @Input() serialNum;
  license: Licenses;
  control;
  generate: boolean;

  constructor(public alertController: AlertController, private context: ContextService, private popover: PopoverController, private simulatorServ: SimulatorService) {
    this.packageNum = '';
    this.iSimulate = '';
    this.scenarioPack = '';
    this.scenarioPackOpt = false;
    this.title = 'Confirm';
    this.message = 'Would you like to add this license?';
    this.generate = false;
   }

  ngOnInit() {
    // console.log(this.context.editSim, this.serialNum);
  }

  licenseAdd(){
    let usr = this.context.getLoggedInUser();

      this.license = new Licenses();
      this.license.iSimulate = this.iSimulate;
      this.license.scenarioPack = this.scenarioPack;
      this.simulatorServ.generateSimulatorLicense(usr, this.serialNum, this.license.iSimulate, '999', '000').subscribe(
            (data) =>{
              // console.log(data[1]);
              // console.log(data[1].Simulator[0].licenses.length);
              this.licensesArr.push(data[1].Simulator[0].licenses[data[1].Simulator[0].licenses.length-1]);

                // let tempSim = new Simulator(sim.productType, sim.gender, sim.ethnicity, sim.productionDate, sim.serialNumber, '',sim.productType);
                // tempSim.woundPattern = sim.woundConfiguration;
                // tempSim.licenses = sim.licenses;
                // this.simList.push(tempSim);
              

            },
          (err) =>{},
          () =>{}
          );
      
  }

  segmentChanged(event){

    this.iSimulate = event.detail.value;
    if(this.iSimulate === '001'){
      this.generate = true;
    }else if (this.scenarioPack !== 'true'){
      this.generate = false;
    }
    // console.log(this.iSimulate);

  }

  serialNumberEdit(event){
   

  }

  selectPackage(event){
    this.scenarioPack = event.detail.value;
    // console.log(event.detail.value);

    if(this.scenarioPack === 'true'){
      this.generate = true;
    }else if (this.iSimulate !== '001'){
      this.generate = false;
    }
  }

  async presentAlert() {
    const alert = await this.alertController.create({
            cssClass: 'alert-class',
            header: this.title,
            message: this.message,
            mode: 'ios',
            buttons: [{
              text: 'YES',
              handler: () => { //takes the data 
                this.licenseAdd();
                alert.dismiss();
                this.popover.dismiss();

              }
            }, {
              text: 'NO',
              handler: () => {
                alert.dismiss();
              }
            }],
    });
  
    await alert.present();
  
    const { role } = await alert.onDidDismiss();
  }
}
