import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import {IonicModule} from "@ionic/angular";
import { CommonModule } from '@angular/common';
import { SimDetailsComponent } from '../components/sim-details/sim-details.component';
import { LicenseGenComponent } from '../components/license-gen/license-gen.component';
import { AddLicenseComponent } from '../components/add-license/add-license.component';
import { NgForm }   from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [HeaderComponent,
                SimDetailsComponent,
                LicenseGenComponent,
              AddLicenseComponent],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [HeaderComponent,
            SimDetailsComponent,
            LicenseGenComponent,
            AddLicenseComponent
    ]
})
export class ComponentsModule {}
